<template>
  <KpiMain></KpiMain>
</template>

<script>
import KpiMain from "@/components/public_infrastructure/InfrastructureMain";
export default {
  name: "InfrastrukturView",
  components:{
    KpiMain
  }
}
</script>

<style scoped>

</style>