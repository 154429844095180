<template>
  <div>

    <v-flex class="d-flex ma-2">

        <v-card>
          <v-navigation-drawer
              permanent
              class="bottom-gradient"
              v-model="drawer"
              :mini-variant.sync="mini"
          >
            <v-text-field
                v-model="search2"
                append-icon="mdi-magnify"
                :label="$t('cari')"
                single-line
                hide-details
                dense
                class="mt-2 mx-2"
                v-if="! mini"
            ></v-text-field>

            <v-list height="1">
                <v-list-item
                    v-for="nav_drawer in nav_drawers.filter(x => x.visible && x.titleId.toLowerCase().includes(search2.toLowerCase()) )"
                    :key="nav_drawer.titleId"
                    link
                    class="navbar_submenu_color"
                >
                  <template>
                    <v-list-item-avatar size="26">
                      <v-img :src="nav_drawer.icon" ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title @click="menuClicked(nav_drawer.id)" class="body-2" v-text="nav_drawer.titleId" > </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
            </v-list>

          </v-navigation-drawer>
        </v-card>

        <v-card width="40%" v-if="false">
          <v-card-title>
            <v-flex class="d-flex">
              <v-btn
                  icon
                  @click.stop="mini = !mini"
              >
                <v-icon>mdi-menu</v-icon>
              </v-btn>
              <div class="ml-2 blue--text font-weight-bold">
                {{ finfrastructureTypeSelected.description }}
              </div>
              <v-spacer></v-spacer>
              <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('cari')"
                  single-line
                  hide-details
                  dense
              ></v-text-field>
            </v-flex>
          </v-card-title>

          <v-card-text>
            <v-data-table
                :headers="headers"
                :items="finfratructures"
                @click:row="handleClick"
                :search="search"
            >

              <template v-slot:[`item.number`]="{ index }">
                {{index+1}}
              </template>

              <template  v-slot:[`item.district`]="{ item }">
                <div class="caption">
                  {{ item.district }}, {{item.city}}
                </div>
              </template>

              <template  v-slot:[`item.detail`]="{ item }">
                <div class="caption" v-if="false">
                  {{ item.detail }}
                </div>
                <div class="text-end" v-if="isWisata">
                  <v-btn small icon @click="showDetailWindow(item)">
                    <v-icon color="orange" small>mdi-eye</v-icon>
                  </v-btn>
                </div>
              </template>


            </v-data-table>
          </v-card-text>
        </v-card>


      <v-flex>
        <InfrastructureMainMap
            ref="refMainmap"
            :finfratructures="finfratructures"
            :itemsFInfrastructureType="itemsFInfrastructureType"
            style="width: 100%" class="ml-1 elevation-5"></InfrastructureMainMap>
      </v-flex>

    </v-flex>

    <v-flex>
      <PublicFooter></PublicFooter>
    </v-flex>

    <InfrastructureDetailDialog
        ref="refDetilDialog"
    ></InfrastructureDetailDialog>

  </div>
</template>

<script>

import InfrastructureMainMap from "./InfrastructureMainMap";
import PublicFooter from "@/components/PublicFooter";
import FInfrastructureTypeService from "../../services/apiservices/f-infrastructure-type-service";
import FInfrastructureService from "@/services/apiservices/f-infrastructure-service";
import FileService from "@/services/apiservices/file-service";
import FSubAreaService from "@/services/apiservices/f-sub-area-service";
import FAreaService from "@/services/apiservices/f-area-service";
import InfrastructureDetailDialog from "@/components/public_infrastructure/InfrastructureDetailDialog";

export default {
  name: 'InfrastructureMain',
  components: {
    InfrastructureDetailDialog,
    InfrastructureMainMap,
    PublicFooter
  },
  data(){
    return{
      drawer: true,
      mini: false,
      finfrastructureTypeSelected: '',
      itemsFInfrastructureType: [],
      itemsFInfrastructure:[],

      search: '',
      search2: '',
      finfratructures:[],
      itemModified: '',

      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        { text: 'Description', value: 'description', width:'15%' },
        { text: 'Alamat', value: 'district', width:'20%' },
        // { text: 'Detail', value: 'detail', width:'10%' },
      ],

    }
  },
  watch:{
    lang: {
      handler: function (val, oldVal) {
        if (val !== oldVal){
          // this.fetchFSectorType()
        }
      }
    },
    tab: function (value){
      this.fetchData(this.itemsFInfrastructureTypeComputed[value])
    }
  },
  computed:{
    lang:{
      get(){
        return this.$store.state.langModule.lang
      },
      set(val) {
        this.$store.dispatch('langModule/setLang', val)
      }
    },
    isWisata(){
      return this.finfrastructureTypeSelected.description.toUpperCase().includes('PELABUHAN') >0
    },
    itemsFInfrastructureTypeComputed(){
      return this.itemsFInfrastructureType.filter( x => x.kawasan ===false)
    },

    nav_drawers(){
      let menus = []
      this.itemsFInfrastructureType.filter( x => x.statusActive===true).forEach(item =>{
        const myMenu =
            {
              id: item.id,
              titleId: this.lang==='en'? item.descriptionEn: item.description,
              icon: this.lookupImageUrlLazy(item),
              active: false,
              visible: true,

              items: [
                {title: 'Laporan', route: '/reports', icon: '', visible:  true},
                {title: 'Komisi', route: '/report-komisi', icon: '', visible:  true},
              ],
            }

          if (item.statusActive===true) {
            menus.push(myMenu)
          }
      })

      // console.log(JSON.stringify(menus))

      return menus
    },

  },
  methods:{
    nav_drawers_sub(sectorTypeBean){
      // console.log(sectorTypeBean)
      let sub_menus = []
      this.itemsFKomoditiType.filter(x => x.fsectorTypeBean == sectorTypeBean).forEach( item =>{
        const newItem = {
          id:item.id, title: item.description, icon: this.lookupImageUrlLazy(item), visible:  true
        }
        if (item.statusActive==true){
          sub_menus.push(newItem)
        }
      })

      return sub_menus
    },
    menuClicked(itemId){
     // console.log(itemId)
      this.finfrastructureTypeSelected = this.lookupFInfratructureType(itemId)
      this.fetchData(this.finfrastructureTypeSelected)

    },
    submenuClick(itemId){
      itemId.toString()
    },

    fetchParent(){
      FSubAreaService.getAllFSubAreaPublic().then(
          response => {
            // console.log(JSON.stringify(response.data))
            // this.itemsFSubArea = response.data
            this.$store.dispatch("potensi/updateItemsFSubArea", response.data)
          }
      )
      FAreaService.getAllFAreaPublic().then(
          response => {
            // this.itemsFArea = response.data
            this.$store.dispatch("potensi/updateItemsFArea", response.data)

          }
      )
      FInfrastructureTypeService.getAllFInfrastructureTypePublic().then(
          response => {
            this.itemsFInfrastructureType = response.data
            this.$store.dispatch('potensi/updateItemsFInfrastructureType', response.data)
          },
          error => {
            console.log(error.response)
          }
      )
    },

    handleClick(value){
      // console.log(value)
      this.itemModified = value
      this.$refs.refMainmap.changeSelection(this.itemModified)
    },

    showDetailWindow(item){
      // console.log(item.description)

      this.itemModified = item
      this.$refs.refDetilDialog.showDialog(this.itemModified, this.itemsFDivision)
      this.$refs.refMainmap.changeSelection(this.itemModified)
    },

    fetchData(item){
        FInfrastructureService.getAllFInfrastructureByTypePublicSimple(item.id).then(
            response =>{
              // console.log(response.data)
              this.finfratructures = response.data
              this.$store.dispatch("potensi/updateItemsFInfrastructure", response.data)
            },
            error =>{
              console.log(error)
            }
        )
    },
    lookupFInfrastructure (finfrastructureBean) {
      const items = this.finfratructures.filter(x => x.id==finfrastructureBean)
      if (items.length>0){
        return items[0]
      }else {
        return Object
      }
    },

    lookupFInfratructureType (finfratructureTypeBean) {
      const str = this.itemsFInfrastructureType.filter(x => x.id===finfratructureTypeBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFSubArea (fsubAreaBean) {
      const str = this.computedItemsFSubArea.filter(x => x.id===fsubAreaBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFArea (fareaBean) {
      const str = this.computedItemsFArea.filter(x => x.id===fareaBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_low(item.avatarImage)
      }
    },
    lookupImageUrlLazy(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },
  },

  mounted() {
    this.fetchParent()
  }
}
</script>

<style scoped>

</style>